<template>
  <q-page class="accountPages">
    <div class="container">
      <div class="page-title hide-in-mobile">
        <h2>Change Time</h2>
        <div class="page-breadcrumb">
          <a
            href="javascript:void(0)"
            @click="applyBack()"
            class="no-underline"
          >
            <SvgIcon icon="back-arrow-line" />Back
          </a>
        </div>
        <div class="row" v-if="!isMobile">
          <Alert
            className="col-md-8 q-mt-sm"
            v-if="orderTimeAlert"
            :variant="orderTimeAlert.variant"
            @dismissed="
              () => {
                this.alertShow = 0
              }
            "
            @dismiss-count-down="
              (countDown) => {
                this.alertShow = countDown
              }
            "
            :show="alertShow"
          >
            <span v-html="orderTimeAlert.message" />
          </Alert>
        </div>
      </div>
      <div class="page-content">
        <!-- -->
        <div class="row">
          <div class="col-12 col-sm-12 col-md-12 orderItemTime">
            <!-- Loading -->
            <div class="orderChangeAddress" v-show="loadingSlot">
              <h6>
                <q-skeleton
                  type="rect"
                  width="100px"
                  height="19px"
                  class="text-caption q-mt-sm"
                />
              </h6>
              <p>
                <q-skeleton
                  type="text"
                  width="100%"
                  class="text-caption q-mt-sm"
                />
                <q-skeleton type="text" width="30%" class="text-caption" />
              </p>
            </div>
            <div class="q-mt-lg" v-show="loadingSlot">
              <q-banner
                inline-actions
                dense
                rounded
                class="alert-warning alert-sm"
              >
                <q-skeleton type="text" width="200px" class="text-caption" />
                <q-skeleton type="text" width="150px" class="text-caption" />
              </q-banner>
            </div>
            <!-- Loading -->
            <div class="orderChangeAddress" v-if="slotInfo">
              <h6 v-html="slotInfo.storeLocation.name"> Store </h6>
              <p
                v-html="$options.filters.formatAddress(slotInfo.storeLocation)"
              />
            </div>
            <div class="q-mt-lg">
              <q-banner
                inline-actions
                dense
                rounded
                class="alert-warning alert-sm"
                v-if="slotInfo"
              >
                You're currently scheduled for
                <strong>
                  {{
                    slotInfo.reservedSlot.bookingDate
                      | formatDate('ddd, MMM Do YY')
                  }}
                  {{ slotInfo.reservedSlot.slotTime }}
                </strong>
                <br />You can reschedule once per order.
              </q-banner>
            </div>
            <div class="q-mt-lg orderChangeTime">
              <DaysList
                :updateField="updateField"
                :slots="slotInfo ? slotInfo.freeSlots : []"
                :activeIndex="slotActiveIndex"
                type="slot"
                :loading="loadingSlot"
              />
              <AvailableSlotList
                :slots="slotInfo ? slotInfo.freeSlots : []"
                :activeIndex="slotActiveIndex"
                :selectionSlot="slotSelectedSlot"
                type="slot"
                :updateField="updateField"
                :loading="loadingSlot"
              />
            </div>
          </div>
          <div class="col-12 col-sm-12 col-md-6"></div>
          <div class="col-12 col-sm-12 col-md-6 q-pt-md q-pb-md">
            <div class="row">
              <div class="col-12 col-sm-6 col-md-6 q-pr-sm q-pl-sm">
                <div class="form-action">
                  <q-btn
                    @click="applyBack()"
                    unelevated
                    no-caps
                    rounded
                    color="tertiary"
                    label="Cancel"
                    class="q-mt-md full-width q-btn-lg"
                  />
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-6 q-pr-sm q-pl-sm">
                <div class="form-action">
                  <q-btn
                    unelevated
                    no-caps
                    rounded
                    color="primary"
                    label="Update"
                    class="q-mt-md full-width q-btn-lg"
                    size="1rem"
                    @click="updateSlot"
                    :loading="submitting"
                    :disable="loadingSlot"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- -->
      </div>
    </div>
  </q-page>
</template>

<script>
import { mapGetters } from 'vuex'
import { date } from 'quasar'

export default {
  /* preFetch({ store, currentRoute, redirect }) {
    let { orderId } = currentRoute.params,
      promises = []

    promises.push(
      store
        .dispatch('order/availableSlots', {
          orderId,
        })
        .then((data) => {
          if (!data || !data.success) {
            console.log('error handling')
          }
        })
    )

    if (process.env.SERVER) return Promise.all(promises)
  }, */
  components: {
    DaysList: () => import('components/reserve-time-slots/DaysList'),
    AvailableSlotList: () =>
      import('components/reserve-time-slots/AvailableSlotList'),
  },
  data() {
    return {
      alertShow: 0,
      submitting: false,
      localSlotActiveIndex: null,
      localSlotSelectedSlot: null,
    }
  },
  watch: {
    orderTimeAlert() {
      this.alertShow = 10
    },
  },
  computed: {
    ...mapGetters('order', ['slotInfoById', 'loadingSlot', 'alertData']),
    orderTimeAlert() {
      return this.alertData('orderTimeAlert')
    },
    slotInfo() {
      return this.slotInfoById(this.orderId)
    },
    selectedDateIndex() {
      if (
        this.slotInfo &&
        this.slotInfo.freeSlots &&
        this.slotInfo.freeSlots.length
      ) {
        let reservedDate = new Date(this.slotInfo.reservedSlot.bookingDate)

        for (const [index, slot] of this.slotInfo.freeSlots.entries()) {
          let slotDate = new Date(slot.date)
          let isEqual = date.isBetweenDates(reservedDate, slotDate, slotDate, {
            onlyDate: true,
            inclusiveFrom: true,
            inclusiveTo: true,
          })
          if (isEqual) {
            return index
          }
        }
      }
      return null
    },
    slotActiveIndex: {
      get() {
        return this.localSlotActiveIndex !== null
          ? this.localSlotActiveIndex
          : this.selectedDateIndex || 0
      },
      set(val) {
        this.localSlotActiveIndex = val
      },
    },
    slotSelectedSlot: {
      get() {
        return this.localSlotSelectedSlot !== null
          ? this.localSlotSelectedSlot
          : this.slotInfo
          ? this.slotInfo.reservedSlot.slotId
          : null
      },
      set(val) {
        this.localSlotSelectedSlot = val
      },
    },
    getSlotDate() {
      if (this.slotInfo.freeSlots && this.slotInfo.freeSlots.length) {
        for (const slot of this.slotInfo.freeSlots) {
          if (slot.availableSlots && slot.availableSlots.length) {
            for (const freeSlot of slot.availableSlots) {
              if (freeSlot.slotId === this.slotSelectedSlot) {
                return slot.date
              }
            }
          }
        }
      }
      return this.slotInfo.reservedSlot.bookingDate
    },
  },
  props: {
    orderId: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  meta() {
    return {
      title: 'Order Edit Time',
      ...this.commonMeta,
    }
  },
  methods: {
    updateField(fieldName, val) {
      this[fieldName] = val
    },
    updateSlot() {
      this.submitting = true
      let params = {
        orderID: this.orderId,
        slotID: this.slotSelectedSlot,
        bookingDate: this.getSlotDate,
      }

      this.$store
        .dispatch('order/updateReservedSlot', params)
        .then((response) => {
          if (response && response.success) {
            this.showSuccess('Time slot modified successfully.')
            this.applyBack()
          } else {
            this.$root.$emit('scroll-to-position')
          }
        })
        .finally(() => {
          this.submitting = false
        })
    },
  },
  destroyed() {
    this.$store.commit('order/CLEAR_TRACK_ORDER_DETAIL', this.orderId)
    if (this.orderTimeAlert)
      this.$store.commit('order/SET_ORDER_ALERT', {
        alertKey: 'orderTimeAlert',
        alertData: null,
      })
  },
  mounted() {
    // prefetch code
    let { orderId } = this.$route.params
    this.$store
      .dispatch('order/availableSlots', {
        orderId,
      })
      .then((data) => {
        if (!data || !data.success) {
          console.log('error handling')
        }
      })
    // prefetch code
  },
}
</script>
<style lang="scss">
.orderItemTime {
  .orderChangeAddress,
  .orderChangeTime {
    border: 1px solid #dadada;
    border-radius: 10px;
  }
  .orderChangeAddress {
    padding: 20px;
    h6 {
      font-size: 16px;
      color: #0f141a;
      margin: 0;
      padding: 0;
      @media (min-width: 1681px) {
        font-size: 20px;
      }
      a {
        font-size: 12px;
      }
    }
    p {
      margin-bottom: 0;
      font-size: 14px;
      line-height: 22px;
      color: #888888;
    }
  }
  .addressDays {
    border-bottom: 1px solid #dadada;
    ul {
      margin: 0;
      padding: 10px 0;
      list-style: none;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-evenly;
      li {
        padding: 10px;
        font-size: 18px;
        text-align: center;
        @media (max-width: 480px) {
          padding: 5px;
        }
        a {
          display: block;
          color: #0f141a;
          text-decoration: none;
        }
        span {
          margin: 0;
          padding: 5px;
          display: block;
          font-size: 14px;
          line-height: 24px;
          color: #314154;
          background: #fafafa;
          border-radius: 10px;
          text-transform: uppercase;
          strong {
            display: block;
            font-weight: normal;
          }
          @media (min-width: 1024px) {
            padding: 10px 15px;
            min-width: 100px;
            strong {
              font-size: 18px;
            }
          }
          @media (min-width: 1681px) {
            font-size: 14px;
            strong {
              font-size: 22px;
            }
          }
          @media (max-width: 480px) {
            font-size: 10px;
          }
        }
        &.current {
          font-weight: bold;
          span {
            color: #fff;
            background: $primary;
            background: var(--q-color-primary) !important;
            font-weight: normal;
            transition: all 0.2s ease;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
          }
        }
      }
    }
  }
  .addressTime {
    .q-item {
      border-bottom: 1px solid #dadada;
      &:last-child {
        border-bottom: none;
      }
    }
  }
}
</style>
